import { Col, Container, Row } from "react-bootstrap"
import projImg1 from "../assets/img/fox-free-icon-font.png"
import projImg2 from "../assets/img/beer-free-icon-font.png"
import projImg3 from "../assets/img/balloons-free-icon-font.png"
import projImg4 from "../assets/img/restaurant-free-icon-font.png"
import projImg5 from "../assets/img/video-camera-free-icon-font.png"
import projImg6 from "../assets/img/sword-free-icon-font.png"
import projImg7 from "../assets/img/christmas-bell.png"
import projImg8 from "../assets/img/santa.png"
import projImg9 from "../assets/img/musik-note.png"
import projImg10 from "../assets/img/handschlag.png"
import projImg11 from "../assets/img/ski.png"


export const Veranstaltungen = () => {

    return(

        <section className="veranstaltungen" id="veranstaltungen">
            <Container>
                <Row>
                    <Col>
                        <h2>Veranstaltungen</h2>
                        <br />
                        <h4>im Wintersemester 2024/25</h4>
                        <br />
                        <br />
                        <div className="eventBox">
                        <div className="eventBox1">
                            <img src={projImg2} alt="" />
                            <h4>Ankneipe</h4>
                            <h5>12.10.2024</h5>
                            <p>20:00 c.t.</p>
                        </div>
                        <div className="eventBox2">
                            <img src={projImg10} alt="" />
                            <h4>SV-Theaterwochenede in Aachen</h4>
                            <h5>18.10.2024 bis 20.10.2024</h5>
                        </div>
                        <div className="eventBox3">
                            <img src={projImg2} alt="" />
                            <h4>Bierbrauen</h4>
                            <h5>25.10.2024 bis 27.10.2024</h5>
                        </div>
                        <div className="eventBox4">
                            <img src={projImg3} alt="" />
                            <h4>Halloween-Party</h4>
                            <h5>01.11.2024</h5>
                            <p>21:00</p>
                        </div>
                        </div>
                            <br />
                            <br />
                            <br />
                        <div className="eventBoxRow2">
                            <div className="eventBoxRow21">
                                <img src={projImg9} alt="" />
                                <h4>SV-Jam-Wochenende in Würzburg</h4>
                                <h5>01.11.2024 bis 03.11.2024</h5>
                            </div>
                            <div className="eventBoxRow22">
                                <img src={projImg1} alt="" />
                                <h4>Fuxen-OE</h4>
                                <h5>08.11.2024</h5>
                                <p>18:00 s.t.</p>
                            </div>
                            <div className="eventBoxRow23">
                                <img src={projImg2} alt="" />
                                <h4>Bierbrauen</h4>
                                <h5>15.11.2024 bis 17.11.2024</h5>
                            </div>
                            <div className="eventBoxRow24">
                                <img src={projImg10} alt="" />
                                <h4>SV-Vertretertag in Sondershausen</h4>
                                <h5>15.11.2024 bis 16.11.2024</h5>
                            </div>
                        </div>

                        <br />
                            <br />
                            <br />
                        <div className="eventBoxRow3">
                            <div className="eventBoxRow31">
                                <img src={projImg9} alt="" />
                                <h4>SV-Musikwochenende in Berlin</h4>
                                <h5>22.11.2024 bis 24.11.2024</h5>
                            </div>
                            <div className="eventBoxRow32">
                                <img src={projImg7} alt="" />
                                <h4>Vorweihnachtlicher Abend (OV)</h4>
                                <h5>07.12.2024</h5>
                                <p>18:00 s.t.</p>
                            </div>
                            <div className="eventBoxRow33">
                                <img src={projImg8} alt="" />
                                <h4>NNKK in Braunschweig</h4>
                                <h5>07.12.2024</h5>
                            </div>
                            <div className="eventBoxRow34">
                                <img src={projImg5} alt="" />
                                <h4>Feuerzangenbowle mit der AMDV Caecilia</h4>
                                <h5>13.12.2024</h5>
                                <p>18:00 s.t.</p>
                            </div>
                        </div>
                        <br />
                            <br />
                            <br />
                        <div className="eventBoxRow3">
                            <div className="eventBoxRow31">
                                <img src={projImg2} alt="" />
                                <h4>Gotenkommers</h4>
                                <h5>14.12.2024</h5>
                                <p>20:00 c.t.</p>
                            </div>
                            <div className="eventBoxRow32">
                                <img src={projImg10} alt="" />
                                <h4>SV-Amtsübergabe in Münster</h4>
                                <h5>03.01.2025 bis 05.01.2025</h5>
                            </div>
                            <div className="eventBoxRow33">
                                <img src={projImg3} alt="" />
                                <h4>OV-Winterball</h4>
                                <h5>01.02.2025</h5>
                            </div>
                            <div className="eventBoxRow34">
                                <img src={projImg2} alt="" />
                                <h4>Abkneipe</h4>
                                <h5>08.02.2025</h5>
                                <p>20:00 c.t.</p>
                            </div>
                        </div>
                        {/*<br />
                            <br />
                            <br />
                        <div className="eventBoxRow3">
                            <div className="eventBoxRow31">
                                <img src={projImg2} alt="" />
                                <h4>Bierbrauen</h4>
                                <h5>27.10.2023 bis 29.10.2023</h5>
                                <h5>&</h5>
                                <h5>17.11.2023 bis 19.11.2023</h5>
                            </div>
                            <div className="eventBoxRow32">
                                <img src={projImg10} alt="" />
                                <h4>OV-Stammtisch</h4>
                                <h5>2. Mittwoch im Monat</h5>
                            </div>
                            <div className="eventBoxRow33">
                                <img src={projImg9} alt="" />
                                <h4>Chorprobe</h4>
                                <h5>Jeden Montag</h5>
                                <p>19:00</p>
                            </div>
                            <div className="eventBoxRow34">
                                <img src={projImg4} alt="" />
                                <h4>Küchenschnack</h4>
                                <h5>TBA</h5>
                            </div>
                        </div>
*/}
{/*
                        <br />
                            <br />
                            <br />
                        <div className="eventBoxRow4">
                            <div className="eventBoxRow41">
                                <img src={projImg11} alt="" />
                                <h4>SV-Skipokal</h4>
                                <h5>10.02. bis 12.02.2023</h5>
                            </div>
                            <div className="eventBoxRow42">
                                <img src={projImg2} alt="" />
                                <h4>Abkneipe</h4>
                                <h5>25.02.2023</h5>
                                <p>20:00 c.t.</p>
                            </div>
                            <div className="eventBoxRow43">
                                <img src={projImg4} alt="" />
                                <h4>Küchenschnack</h4>
                                <h5>TBA</h5>
                            </div>
                            <div className="eventBoxRow44">
                                <img src={projImg1} alt="" />
                                <h4>Fuxenstunden</h4>
                                <h5>TBA</h5>
                            </div>
                        </div>
                        */}
                    </Col>
                </Row>
            </Container>
        </section>

    )
}